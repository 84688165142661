import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  darkColor,
  lightColor,
  lightColorHover,
  primaryColor,
} from "../../colors";

export const FooterContainer = styled.footer`
  background-color: ${darkColor};
  overflow-x: hidden;
`;

export const FooterWrap = styled.div`
  padding: 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;
export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  @media screen and (maxwidth: 780px) {
    padding-top: 32px;
  }
`;

export const FooterLOGOWrapper = styled.div`
  display: flex;
  @medi screen and (max-width: 780px) {
    flex-direction: column;
  }
`;
export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 5px;
  text-align: center;
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  background-color: ${darkColor};
  color: ${lightColor};
  transition: all 0.2s ease-in-out;
  @medi screen and (max-width: 420px) {
    justify-content: center;
    align-items: center;
    padding: 10 px;
  }
  @media screen and (max-width: 470px) {
    width: 100px;
    height: 100px;
  }
  @media screen and (max-width: 450px) {
    width: 70px;
    height: 70px;
  }
  &:hover {
    transform: scale(1.06);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
`;

export const FooterLinkTitle = styled.h1`
  font-size: 35px;
  font-family: BigFont;
  text-align: center;
  margin-bottom: 2px;
  color: #1e90ff;
`;

export const FooterLink = styled.a`
color:${lightColor};
text-decoration: none;
margin-bottom: 0.5rem;
font-size 14px;


&:hover {
    color: ${lightColorHover};
    transition: 0.3s ease-out;
}
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;
export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: auto auto auto auto;

  @media screen and (max-width: 780px) {
    flex-direction: column;
  }
`;
export const SocialLogo = styled(Link)`
  color: ${lightColor};
  justify-self: start;
  curser: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
  background-color: red;
`;

export const WebsiteRights = styled.small`
  color: #1e90ff;
  margin-bottom: 16px;
  margin-top: -10px;
  text-align: center;
  margin-left: 70px;
  @media screen and (max-width: 780px) {
    margin-left: 0px;
  }
`;
export const WebsiteRights2 = styled.small`
  color: #1e90ff;
  text-align: center;
  margin-bottom: 16px;
  margin-top: -10px;
`;

export const BroImageWrap = styled.div`
  display: flex;
  position: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
`;

export const SocialIconLink = styled.a`
  font-size: 24px;
  width: 100%;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
  postion: flex;
  object-fit: contain;
  -o-object-fit: contain;
`;
export const BroImg = styled.img`
  width: 250px;
  height: 250px;
  postion: relative;
  margin-top: -150px;
  object-fit: contain;
  -o-object-fit: contain;
  @media screen and (max-width: 780px) {
    display: none;
  }
  @media screen and (max-width: 1000px) {
    margin-top: -120px;
  }
`;
export const FooterNameTag = styled.p`
  border-radius: 10px;
  position: relative;
  max-width: 80px;
  left: 70%;
  font-size: 0.7rem;
  margin-top: -400px;
  background: #563f15;
  color: ${lightColor};
  text-align: center;
  padding: 1px 5px;
  @media screen and (max-width: 1000px) {
    margin-top: -300px;
  }
  @media screen and (max-width: 780px) {
    display: none;
  }
`;
export const QuoteH2 = styled.h2`
  font-size: 1rem;
  text-align: center;
  font-family: "ItalicFont";
  position: absolute;
  margin-top: 70px;
  color: ${lightColor};
  text-shadow: 0 0 10px #000;
  @media screen and (max-width: 1000px) {
    margin-top: 120px;
    font-size: 0.8rem;
  }
  @media screen and (max-width: 780px) {
    display: none;
  }
`;
