import React, { Component } from "react";
import "./App.css";
import Home from "./pages/LandingPage";
import Brewery from "./pages/Brewery";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export class App extends Component {
  render() {
    var root = document.querySelector(":root");
    root.style.setProperty("--toastify-toast-width", "fit-content");
    return (
      <Router>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/FundProj" component={Brewery} exact />
        </Switch>
        <ToastContainer />
      </Router>
    );
  }
}

export default App;
