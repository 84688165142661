import React from "react";
import {
  FooterLinkItems,
  FooterLOGOWrapper,
  SocialIconLink,
} from "../Footer/FooterElements";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  Topline,
  Heading,
  Subtitle,
  IMgWrap,
  Img,
} from "./CelestialInfoElements";
import { UkraineLinkItems } from "./InfoElements";
const InfoSectionUkraine = ({
  lightBg,
  id,
  imgStart,
  topline,
  lightText,
  headline,
  darkText,
  description,
  img,
  alt,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Topline lightText={lightText}>{topline}</Topline>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
                <FooterLOGOWrapper>
                  <UkraineLinkItems>
                    <SocialIconLink
                      aria-label="HelpUkraine.Art"
                      onClick={(e) => {
                        e.preventDefault();
                        window.location.href = "https://helpukraine.art";
                      }}
                    >
                      <Img
                        src={require("../../images/HelpUkraine.png")?.default}
                        alt="HelpUkrain.art Icon"
                      />
                    </SocialIconLink>
                  </UkraineLinkItems>
                </FooterLOGOWrapper>
              </TextWrapper>
            </Column1>
            <Column2>
              <IMgWrap>
                <Img src={img} alt={alt} />
              </IMgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSectionUkraine;
