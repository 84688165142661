import styled from "styled-components";
import {
  darkColor,
  lightColor,
  primaryColor,
  primaryHoverColor,
} from "../colors";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";
export const Button = styled(LinkS)`
  border-radius: 20px;
  max-height: 40px;
  max-width: 200px;
  background: ${({ primary }) => (primary ? primaryColor : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 44px" : "10px 28px")};
  color: ${({ dark }) => (dark ? darkColor : lightColor)};
  font-size: ${({ fontbig }) => (fontbig ? "19px" : "15px")};
  outline: none;
  border: none;
  curser: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-top: 10px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? primaryHoverColor : "#010606")};
    color: ${({ dark }) => (dark ? lightColor : darkColor)};
  }
`;
export const ButtonRoute = styled(LinkR)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? primaryColor : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? darkColor : lightColor)};
  font-size: ${({ fontbig }) => (fontbig ? "20px" : "16px")};
  outline: none;
  border: none;
  curser: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? primaryHoverColor : "#ff2a6d")};
    color: ${({ dark }) => (dark ? lightColor : darkColor)};
  }
`;
