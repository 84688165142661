import React, { useState, useEffect } from "react";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavBtn,
  NavLinkR,
  NLImg,
} from "./NavbarElements";
import LoginComponent from "../LoginComponent";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import { useLocation } from "react-router-dom";
import { lightColor, primaryColor } from "../../colors";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const location = useLocation();
  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <IconContext.Provider value={{ color: lightColor }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              <NLImg
                src={require("../../images/web/Logo_Schrift.webp")?.default}
                alt={"Yeast brothers logo"}
              ></NLImg>
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars style={{ color: primaryColor }} />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinkR
                  to="/"
                  offset={-80}
                  active={location.pathname === "/" ? true : false}
                >
                  About the Humanity Pool
                </NavLinkR>
              </NavItem>
              <NavItem>
                <NavLinkR
                  to="/FundProj"
                  offset={-80}
                  active={location.pathname === "/FundProj" ? true : false}
                >
                  Funded Projects
                </NavLinkR>
              </NavItem>
            </NavMenu>
            <NavBtn>
              <LoginComponent />
            </NavBtn>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
