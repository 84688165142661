import React, { useState } from "react";
import { lightColor } from "../../colors";
import BuyComponent from "../BuyComponent";
import {
  FooterLinkItems,
  FooterLOGOWrapper,
  Img,
  SocialIconLink,
} from "../Footer/FooterElements";
import {
  ArrowForward,
  ArrowRight,
  BuyWrap,
  ImageBG,
  ImgButton,
  InfoRow,
  SoonBG,
  SoonContainer,
  SoonContent,
  SoonH1,
} from "./ComingSoonElements";
const ComingSoonSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <SoonContainer id="Soon">
      <SoonBG>
        <ImageBG src={require("../../images/Wald_Bg2.jpg")?.default} />
      </SoonBG>
      <SoonContent>
        <SoonH1>Donate to helpukraine.art</SoonH1>
        <p style={{ color: lightColor, marginBottom: "20px", width: "500px" }}>
          {" "}
          We support a project run by Cardano enthusiasts from Ukraine and NFT
          artists from Ukraine They will for now donate all funds to the
          Ministry of Digital Transformation of Ukraine.
        </p>
        <InfoRow>
          <BuyWrap>
            <BuyComponent price={5} buttonText={"5 ADA"} />
          </BuyWrap>
          <BuyWrap>
            <BuyComponent price={50} buttonText={"10 ADA"} />
          </BuyWrap>
          <BuyWrap>
            <BuyComponent
              price={50}
              buttonText={"50 ADA - get an NFT"}
              style={{ maxWidth: "300px" }}
            />
          </BuyWrap>
          <BuyWrap>
            <BuyComponent price={200} buttonText={"200 ADA - get an NFT"} />
          </BuyWrap>
        </InfoRow>
        <p style={{ color: lightColor, marginTop: "20px" }}>
          {" "}
          For other wallets / amounts you may send donations to:
        </p>
        <p
          style={{ color: lightColor, marginBottom: "20px", fontSize: "1.5vw" }}
        >
          "addr1qy06qp48l690axapssf3w06efkefff9sdr2tq4wcmn0msmx6779m2x9ja2tvcuqr50wstem0f82q95v42r7sx6etfu2q07enaz"
        </p>
      </SoonContent>
    </SoonContainer>
  );
};

export default ComingSoonSection;
