import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer/indexWOdudes";
//import Cube from "../components/Brewery/OBJ_Example";
import ThreeSceneGLB from "../components/Brewery/GLB-PointerlockControls";
import ComingSoonSection from "../components/Coming-Soon";
import FundedProjects from "../components/FundedProjects";
const Brewery = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <FundedProjects />

      {/* <ThreeSceneGLB />*/}
      <Footer />
    </div>
  );
};

export default Brewery;
