import React, { Component } from "react";
import { Button } from "./ButtonElement.js";
import { Buffer } from "buffer";
import Loader from "./Loader.js";
import { toast } from "react-toastify";

export default class LoginComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEnabled: false,
      connectText: "Connect",
      namiWallet: undefined,
      balance: 0,
      colleteral: [],
      adress: "",
    };
  }

  loginSuccessful = () => toast.success("Login successful!");

  isEnabled = (namiWallet) => {
    if (namiWallet) {
      var result = namiWallet.isEnabled();
      if (result) {
        this.getBalance(namiWallet);
        this.getAddress(namiWallet);
        this.getColleteral(namiWallet);
      }
      return result;
    }
    return false;
  };

  getAddress = (namiWallet) => {
    if (namiWallet) {
      namiWallet.getUsedAddresses().then((value) => {
        if (value) {
          this.setState({
            adress: Loader.Cardano.Address.from_bytes(
              Buffer.from(value[0], "hex")
            ).to_bech32(),
          });
        }
      });
    }
    return "";
  };

  getColleteral = (namiWallet) => {
    if (namiWallet) {
      namiWallet.getCollateral().then((value) => {
        if (value) {
          var colleteralUtxos = [];
          value.forEach((u) => {
            colleteralUtxos.push(
              Loader.Cardano.TransactionUnspentOutput.from_bytes(
                Buffer.from(u, "hex")
              )
            );
          });
          this.setState({
            colleteral: colleteralUtxos,
          });
        }
      });
    }
  };

  getBalance = (namiWallet) => {
    if (namiWallet) {
      namiWallet.getBalance().then((value) =>
        this.setState({
          balance: Loader.Cardano.Value.from_bytes(Buffer.from(value, "hex"))
            .coin()
            .to_str(),
        })
      );
    }
  };

  componentDidMount() {
    Loader.load()
      .then(() => {
        var namiWallet = window.cardano;
        if (!namiWallet) {
          this.setState({
            isEnabled: false,
            connectText: "Install Nami",
          });
          return;
        } else {
          namiWallet.onAccountChange((adresses) =>
            this.isEnabled(window.cardano)
          );
          namiWallet.onNetworkChange((network) =>
            this.isEnabled(window.cardano)
          );
          this.setState({
            namiWallet: namiWallet,
            isEnabled: namiWallet.isEnabled(),
          });
          return Promise.resolve(namiWallet.isEnabled());
        }
      })
      .then((isEnabled) => {
        this.setState({
          isEnabled: isEnabled,
        });
        this.isEnabled(this.state.namiWallet);
      });
  }

  openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  noColleteral(collateral) {
    console.log(collateral.length);
    if (collateral) {
      return collateral.length == 0;
    }
  }
  formatAdress(adress) {
    return (
      adress.slice(0, 4) +
      "..." +
      adress.slice(adress.length - 4, adress.length)
    );
  }

  render() {
    return (
      <div>
        <Button
          primary
          dark
          style={{ whiteSpace: "normal" }}
          onClick={() => {
            if (!this.state.namiWallet) {
              //open new tab for https://namiwallet.io/;
              this.openInNewTab("https://namiwallet.io/");
            }
            if (this.state.namiWallet && !this.state.isEnabled) {
              this.state.namiWallet.enable().then((value) => {
                if (value) {
                  this.loginSuccessful();
                  var isEnabled = this.isEnabled(this.state.namiWallet);
                  this.setState({ isEnabled: isEnabled });
                }
              });
            }
          }}
        >
          <p>
            {this.state.isEnabled && this.state.adress.length > 0
              ? this.formatAdress(this.state.adress)
              : this.state.connectText}
            {/*this.noColleteral(this.state.colleteral) ? "Add colleteral" : ""*/}
          </p>
        </Button>
      </div>
    );
  }
}
