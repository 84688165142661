import React, { Component } from "react";
import { Button } from "./ButtonElement";
import { Buffer } from "buffer";
import CoinSelection from "./CoinSelection";
import Loader from "./Loader";
import { toast } from "react-toastify";

export default class BuyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEnabled: false,
      namiWallet: undefined,
      mainNet: undefined,
      balance: 0,
    };
  }

  noInternetConnection = () => toast.error("No Internet connection found!");
  noNetworkError = () => toast.error("No Network detected!");
  insufficientFundsError = (ada) =>
    toast.error(
      "Insufficient funds! It costs " + ada + " ADA plus a transaction fee."
    );
  blockfrostSeverError = () => toast.error("Blockfrost server error!");
  connectWalletFirst = () => toast.error("Connect Wallet first!");
  transactionFailed = () => toast.error("Transaction failed!");
  transactionCanceled = () => toast.info("Transaction was canceled!");
  transactionSuccess = (txHash) =>
    toast.success("Transaction successfully sent! TXHash: " + txHash);

  getAddress = () => {
    if (this.state.namiWallet) {
      this.state.namiWallet.getUsedAddresses().then((value) => {
        if (value) {
          this.setState({
            adress: Loader.Address.from_bytes(
              Buffer.from(value[0], "hex")
            ).to_bech32(),
          });
        }
      });
    }
    return "";
  };

  getProtocolParameters = async () => {
    var api_url = "noApiUrl";
    var projectID = "ID";
    if (this.state.mainNet === true) {
      api_url = "https://cardano-mainnet.blockfrost.io/api/";
      // Hier ist das porjectID setzen eigentlich egal, da die Parameter von Main und Testnet sich nicht (oder kaum)
      // unterscheiden. Kann aber natürlich sein, daher zur sicherheit zwei BlockfrostIDs.
      projectID = "fDOiiiKKbfRJSrm5OaT3p3sjrTdJ5djh";
    } else {
      api_url = "https://cardano-testnet.blockfrost.io/api/";
      projectID = "testnetumiwyJZdMYXDHGoxFMAsYWTVUOtiU396";
    }

    const latest_block = await fetch(api_url + "v0/blocks/latest", {
      headers: {
        project_id: projectID,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .catch((error) => {
        this.noInternetConnection();
        return error;
      });
    if (latest_block instanceof Error) {
      return;
    }
    var slotnumber = latest_block.slot;

    const p = await fetch(api_url + "v0/epochs/latest/parameters", {
      headers: {
        project_id: projectID,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    }).then((response) => response.json());
    if (p.status >= 400 && p.status < 600) {
      this.blockfrostSeverError();
      return;
    }
    var value = {
      linearFee: Loader.Cardano.LinearFee.new(
        Loader.Cardano.BigNum.from_str(p.min_fee_a.toString()),
        Loader.Cardano.BigNum.from_str(p.min_fee_b.toString())
      ),
      minUtxo: Loader.Cardano.BigNum.from_str(p.min_utxo),
      poolDeposit: Loader.Cardano.BigNum.from_str(p.pool_deposit),
      keyDeposit: Loader.Cardano.BigNum.from_str(p.key_deposit),
      maxTxSize: p.max_tx_size,
      slot: slotnumber,
    };
    return value;
  };

  triggerPay = async () => {
    if ((await this.state.namiWallet.getNetworkId()) === 1) {
      this.state.mainNet = true;
      var address =
        "addr1qy06qp48l690axapssf3w06efkefff9sdr2tq4wcmn0msmx6779m2x9ja2tvcuqr50wstem0f82q95v42r7sx6etfu2q07enaz";
    } else if ((await this.state.namiWallet.getNetworkId()) === 0) {
      this.state.mainNet = false;
      var address =
        "addr_test1qzlc0lq87x3qlnhgjc27ytkglzas7cwdl8u2hrn8ner02e5swxs0hw4nq429f7cnun68csc5uqxxd7cd2j26jjus7f5qd8ccg2";
    } else {
      this.noNetworkError();
      return "";
    }
    var offer = this.props.price ? this.props.price : 5;

    return this.pay(offer, address);
  };

  pay = async (ada, adress) => {
    await Loader.load();
    const cardano = this.state.namiWallet;
    const protocolParameters = await this.getProtocolParameters();
    const lovelace = (parseFloat(ada) * 1000000).toString();
    const paymentAddr = Loader.Cardano.Address.from_bytes(
      Buffer.from(await cardano.getChangeAddress(), "hex")
    ).to_bech32();

    const rawUtxo = await cardano.getUtxos();
    const utxos = rawUtxo.map((u) =>
      Loader.Cardano.TransactionUnspentOutput.from_bytes(Buffer.from(u, "hex"))
    );

    const outputs = Loader.Cardano.TransactionOutputs.new();
    outputs.add(
      Loader.Cardano.TransactionOutput.new(
        Loader.Cardano.Address.from_bech32(adress),
        Loader.Cardano.Value.new(Loader.Cardano.BigNum.from_str(lovelace))
      )
    );

    const MULTIASSET_SIZE = 5848;
    const VALUE_SIZE = 5860;
    const totalAssets = 0;

    CoinSelection.setProtocolParameters(
      protocolParameters.minUtxo.to_str(),
      protocolParameters.linearFee.coefficient().to_str(),
      protocolParameters.linearFee.constant().to_str(),
      protocolParameters.maxTxSize.toString()
    );

    const selection = await new Promise((resolve, reject) => {
      resolve(CoinSelection.randomImprove(utxos, outputs, 20 + totalAssets));
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        this.insufficientFundsError(ada);
        return error;
      });
    if (selection instanceof Error) {
      return;
    }

    const inputs = selection.input;
    const txBuilder = Loader.Cardano.TransactionBuilder.new(
      protocolParameters.linearFee, //linear_fee
      protocolParameters.minUtxo, //min_utxo
      protocolParameters.poolDeposit, //pool_deposit
      protocolParameters.keyDeposit, //key_deposit
      100000, //max_value_size
      protocolParameters.maxTxSize //max_tx_size
    );

    for (let i = 0; i < inputs.length; i++) {
      const utxo = inputs[i];
      txBuilder.add_input(
        utxo.output().address(),
        utxo.input(),
        utxo.output().amount()
      );
    }
    txBuilder.add_output(outputs.get(0));

    const change = selection.change;
    const changeMultiAssets = change.multiasset();
    // check if change value is too big for single output
    if (changeMultiAssets && change.to_bytes().length * 2 > VALUE_SIZE) {
      const partialChange = Loader.Cardano.Value.new(
        Loader.Cardano.BigNum.from_str("0")
      );
      const partialMultiAssets = Loader.Cardano.MultiAsset.new();
      const policies = changeMultiAssets.keys();
      const makeSplit = () => {
        for (let j = 0; j < changeMultiAssets.len(); j++) {
          const policy = policies.get(j);
          const policyAssets = changeMultiAssets.get(policy);
          const assetNames = policyAssets.keys();
          const assets = Loader.Cardano.Assets.new();
          for (let k = 0; k < assetNames.len(); k++) {
            const policyAsset = assetNames.get(k);
            const quantity = policyAssets.get(policyAsset);
            assets.insert(policyAsset, quantity);
            //check size
            const checkMultiAssets = Loader.Cardano.MultiAsset.from_bytes(
              partialMultiAssets.to_bytes()
            );
            checkMultiAssets.insert(policy, assets);
            if (checkMultiAssets.to_bytes().length * 2 >= MULTIASSET_SIZE) {
              partialMultiAssets.insert(policy, assets);
              return;
            }
          }
          partialMultiAssets.insert(policy, assets);
        }
      };
      makeSplit();
      partialChange.set_multiasset(partialMultiAssets);
      const minAda = Loader.Cardano.min_ada_required(
        partialChange,
        protocolParameters.minUtxo
      );
      partialChange.set_coin(minAda);

      txBuilder.add_output(
        Loader.Cardano.TransactionOutput.new(
          Loader.Cardano.Address.from_bech32(paymentAddr),
          partialChange
        )
      );
    }

    txBuilder.add_change_if_needed(
      Loader.Cardano.Address.from_bech32(paymentAddr)
    );

    const transaction = Loader.Cardano.Transaction.new(
      txBuilder.build(),
      Loader.Cardano.TransactionWitnessSet.new()
    );

    const size = transaction.to_bytes().length * 2;
    if (size > protocolParameters.maxTxSize) {
      this.transactionFailed();
      console.log("txTooBig");
      return;
    }

    const witneses = await cardano
      .signTx(Buffer.from(transaction.to_bytes(), "hex").toString("hex"))
      .then((result) => {
        return result;
      })
      .catch((error) => {
        this.transactionCanceled();
        return error;
      });
    console.log(witneses);
    console.log(transaction.body());
    if (witneses.code) {
      return;
    }

    const signedTx = Loader.Cardano.Transaction.new(
      transaction.body(),
      Loader.Cardano.TransactionWitnessSet.from_bytes(
        Buffer.from(witneses, "hex")
      )
    );
    const txhash = await new Promise((resolve, reject) => {
      resolve(
        cardano.submitTx(
          Buffer.from(signedTx.to_bytes(), "hex").toString("hex")
        )
      );
    })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        this.transactionFailed();
        return error;
      });
    if (selection instanceof Error) {
      return;
    }
    console.log(txhash);
    this.transactionSuccess(txhash);
    return txhash;
  };

  getBalance = (namiWallet) => {
    if (namiWallet) {
      namiWallet.getBalance().then((value) =>
        this.setState({
          balance: Loader.Cardano.Value.from_bytes(Buffer.from(value, "hex"))
            .coin()
            .to_str(),
        })
      );
    }
  };

  isEnabled = (namiWallet) => {
    if (namiWallet) {
      var result = namiWallet.isEnabled();
      if (result) {
        this.getBalance(namiWallet);
      }
      return result;
    }
    return false;
  };

  componentDidMount() {
    Loader.load()
      .then(() => {
        var namiWallet = window.cardano;
        if (!namiWallet) {
          this.setState({
            isEnabled: false,
          });
          return;
        } else {
          namiWallet.onAccountChange((adresses) =>
            this.isEnabled(window.cardano)
          );
          namiWallet.onNetworkChange((network) =>
            this.isEnabled(window.cardano)
          );
          this.setState({
            namiWallet: namiWallet,
            isEnabled: namiWallet.isEnabled(),
          });
          return Promise.resolve(namiWallet.isEnabled());
        }
      })
      .then((isEnabled) => {
        this.setState({
          isEnabled: isEnabled,
        });
        this.isEnabled(this.state.namiWallet);
      });
  }

  ButtonClick = () => {
    if (!this.state.isEnabled) {
      this.connectWalletFirst();
      return;
    }
    let txHash = this.triggerPay();
    console.log(txHash);
  };
  render() {
    return (
      <div>
        <Button
          primary
          dark
          onClick={() => {
            this.ButtonClick();
          }}
        >
          {this.state.isEnabled
            ? this.props.buttonText
              ? this.props.buttonText
              : "Buy"
            : "Connect Wallet"}
        </Button>
      </div>
    );
  }
}
