import styled from "styled-components";
import {
  darkColor,
  lightColor,
  primaryHoverColor,
  primaryColor,
} from "../../colors";
export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#010606")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 800px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-bottom: 100px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-colums: minmax(auto, 1fr);
  align-items: center;

  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;
export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;
export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const Topline = styled.p`
  color: ${({ lightText }) => (lightText ? "#05d9e8" : "#ff2a6d")};
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color ${({ darkText }) => (darkText ? "#010606" : "#fff")}
`;
export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const IMgWrap = styled.div`
  max-width: 450px;
  height: 100%;
`;
export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;

export const UkraineLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 5px;
  text-align: center;
  width: 120px;
  height: 120px;
  box-sizing: border-box;
  background-color: ${darkColor};
  color: ${lightColor};
  transition: all 0.2s ease-in-out;
  @medi screen and (max-width: 420px) {
    justify-content: center;
    align-items: center;
    padding: 10 px;
  }
  @media screen and (max-width: 470px) {
    width: 100px;
    height: 100px;
  }
  @media screen and (max-width: 450px) {
    width: 70px;
    height: 70px;
  }
  &:hover {
    transform: scale(1.5);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
`;

export const Button = styled.button`
  border-radius: 20px;
  max-height: 40px;
  max-width: 700px;
  background: ${({ primary }) => (primary ? primaryColor : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 44px" : "10px 28px")};
  color: ${({ dark }) => (dark ? darkColor : lightColor)};
  font-size: ${({ fontbig }) => (fontbig ? "19px" : "15px")};
  outline: none;
  border: none;
  curser: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-top: 10px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? primaryHoverColor : "#010606")};
    color: ${({ dark }) => (dark ? lightColor : darkColor)};
  }
`;
