import React, { useState } from "react";
import { lightColor } from "../../colors";
import BuyComponent from "../BuyComponent";
import {
  FooterLinkItems,
  FooterLOGOWrapper,
  Img,
  SocialIconLink,
} from "../Footer/FooterElements";
import {
  ArrowForward,
  ArrowRight,
  Button,
  BuyWrap,
  ImageBG,
  ImgButton,
  InfoRow,
  SoonBG,
  SoonContainer,
  SoonContent,
  SoonH1,
} from "./ComingSoonElements";
const FundedProjects = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <SoonContainer id="Soon">
      <SoonBG>
        <ImageBG src={require("../../images/Wald_Bg2.jpg")?.default} />
      </SoonBG>
      <SoonContent>
        <SoonH1>
          First donations go to Minsitry of Digital Transformation of Ukraine
        </SoonH1>
        <p style={{ color: lightColor, marginBottom: "20px", width: "500px" }}>
          {" "}
          When staking with us, we will donate the rewards to the Ministry of
          Digital Transformation of Ukraine (supporting the government) and the
          K41 community fund ( supporting the refuge of artists and creatives
          from Ukraine). If you want to donate your ADA on your own, consider
          donating to "HelpUkraine.art". For donations of 50 ADA and above, you
          will receive unique NFTs by ukrainian artists! The donate buttons
          below are directly wired to their wallet. Simply click and donate
          using NAMI wallet below
        </p>

        <InfoRow>
          <BuyWrap>
            <BuyComponent price={5} buttonText={"5 ADA"} />
          </BuyWrap>
          <BuyWrap>
            <BuyComponent price={50} buttonText={"10 ADA"} />
          </BuyWrap>
          <BuyWrap>
            <BuyComponent price={50} buttonText={"50 ADA - get an NFT"} />
          </BuyWrap>
          <BuyWrap>
            <BuyComponent price={200} buttonText={"200 ADA - get an NFT"} />
          </BuyWrap>
        </InfoRow>
        <p style={{ color: lightColor, marginTop: "20px" }}>
          {" "}
          For other wallets / amounts you may send donations to:
        </p>
        <p
          style={{ color: lightColor, marginBottom: "20px", fontSize: "1.5vw" }}
        >
          "addr1qy06qp48l690axapssf3w06efkefff9sdr2tq4wcmn0msmx6779m2x9ja2tvcuqr50wstem0f82q95v42r7sx6etfu2q07enaz"
        </p>

        <Button
          primary
          light
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "https://donate.thedigital.gov.ua";
          }}
        >
          Webpage of the Ministry of digital transformation.
        </Button>
        <Button
          primary
          light
          onClick={(e) => {
            e.preventDefault();
            window.location.href = "https://k41community.fund/";
          }}
        >
          Webpage of the K41 community fund.
        </Button>
      </SoonContent>
    </SoonContainer>
  );
};

export default FundedProjects;
