import React from "react";
import {
  SidebarContainer,
  CloseIcon,
  Icon,
  SidebarWrapper,
  SidebarLink,
  SideBTNWrap,
  SidebarMenu,
  SidebarRoute,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>

      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="/" onClick={toggle}>
            Humanity-Pool
          </SidebarLink>
          <SidebarLink to="/FundProj" onClick={toggle}>
            Funded projects
          </SidebarLink>
        </SidebarMenu>
        <SideBTNWrap>
          <SidebarRoute
            aria-label="Discord"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "https://discord.gg/ZSYVzHmzH9";
            }}
          >
            Discord
          </SidebarRoute>
        </SideBTNWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
