import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";
import { Link as LinkR } from "react-router-dom";
import {
  darkColor,
  lightColor,
  primaryColor,
  primaryHoverColor,
} from "../../colors";

export const SoonContainer = styled.div`
background: #0c0c0c;
display: flex;
justify-content: center;
align-items: center;
position: relative;
z-index: 1;
background: ${lightColor};
overflow-x: hidden;

:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%)
  z-index: 2;
}
@media screen and (max-width: 470px) {
  margin-top: 80px;
}
`;

export const SoonBG = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const ImageBG = styled.img`
  width: 100%;
  display: flex;
  min-height: 730px;
  object-fit: cover;
  -o-object-fit: cover;
  background: ${lightColor};
`;

export const SoonContent = styled.div`
  z-index: 3;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const SoonH1 = styled.h1`
  color: ${lightColor};
  font-size: 45px;
  font-family: BigFont;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 20px;
  text-shadow: 0 0 5px #000;

  @media screen and (max-width: 768px) {
    font-size: 30px;
  }
  @media screen and (max-width: 560px) {
    font-size: 30px;
  }
  @media screen and (max-width: 470px) {
    font-size: 20px;
    margin-top: 40px;
  }
`;

export const ImgButton = styled(LinkR)`
  border-radius: 50px;
  margin-top: 10px;
  max-width: 150px;
  background: ${({ primary }) => (primary ? primaryColor : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? darkColor : lightColor)};
  font-size: ${({ fontbig }) => (fontbig ? "20px" : "16px")};
  outline: none;
  border: none;
  text-decoration: none;
  curser: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 768px) {
    border-radius: 30px;
  }
  @media screen and (max-width: 480px) {
    border-radius: 20px;
  }
  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? primaryHoverColor : "#ff2a6d")};
    color: ${({ dark }) => (dark ? lightColor : darkColor)};
  }
`;
export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 24px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
  font-size: 24px;
`;

export const BtnMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
`;
export const InfoRow = styled.div`
  display: grid;
  grid-auto-colums: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 400px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const BuyWrap = styled.div`
  padding: 10px;
`;

export const Button = styled.button`
  border-radius: 20px;
  max-height: 40px;
  max-width: 700px;
  background: ${({ primary }) => (primary ? primaryColor : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "12px 44px" : "10px 28px")};
  color: ${({ dark }) => (dark ? darkColor : lightColor)};
  font-size: ${({ fontbig }) => (fontbig ? "19px" : "15px")};
  outline: none;
  border: none;
  curser: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  margin-top: 10px;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? primaryHoverColor : "#010606")};
    color: ${({ dark }) => (dark ? lightColor : darkColor)};
  }
`;
