import React from "react";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLOGOWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  Img,
  BroImg,
  FooterNameTag,
  QuoteH2,
  WebsiteRights2,
} from "./FooterElements";
import {
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  BroImageWrap,
  SocialIconLink,
} from "./FooterElements";
const Footer = () => {
  return (
    <>
      <FooterContainer id="links">
        {/* The container & Wrapper stretch over everything */}
        <FooterWrap>
          <FooterLinkTitle>
            Simply delegate without any loss & become a real hero!
          </FooterLinkTitle>
          <FooterLinksContainer>
            <FooterLOGOWrapper>
              <FooterLinkItems>
                <SocialIconLink
                  aria-label="Twitter"
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "https://twitter.com/humanity_pool";
                  }}
                >
                  <Img
                    src={require("../../images/web/Twitter.webp")?.default}
                    alt="Twitter Icon"
                  />
                </SocialIconLink>
              </FooterLinkItems>
            </FooterLOGOWrapper>
            <FooterLOGOWrapper>
              <FooterLinkItems>
                <SocialIconLink aria-label="Discord">
                  {/* onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "https://discord.gg/ZSYVzHmzH9";
                  }} */}
                  <Img
                    src={require("../../images/web/Discord.webp")?.default}
                    alt="Discord Icon"
                  />
                </SocialIconLink>
              </FooterLinkItems>
            </FooterLOGOWrapper>
            <FooterLOGOWrapper>
              <FooterLinkItems>
                <SocialIconLink aria-label="Reddit">
                  {/*  MOVE THIS INTO THE PREVIOUS TRIANGLE:
                   onClick={(e) => { 
                    e.preventDefault();
                    window.location.href =
                      "https://www.reddit.com/r/YeastBrothers/";
                  }} */}
                  <Img
                    src={require("../../images/web/Reddit.webp")?.default}
                    alt="Reddit Icon"
                  />
                </SocialIconLink>
              </FooterLinkItems>
            </FooterLOGOWrapper>
          </FooterLinksContainer>

          <WebsiteRights2>
            Humanity Pool © {new Date().getFullYear()} All rights reserverd.
            Powered by Cardano.
          </WebsiteRights2>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;
