import React, { useState } from "react";
import Footer from "../components/Footer/indexWOdudes";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

import ComingSoonSection from "../components/Coming-Soon";
import InfoSection from "../components/InfoSection/index";
import { HPinfo, HUinfo } from "../components/InfoSection/data";
import InfoSectionUkraine from "../components/InfoSection/HelpukraineArt";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      style={{
        background: "#15130e",
        backgroundImage: `url(${
          require("../images/web/BGoptimized.webp")?.default
        })`,

        backgroundSize: "1500px 750px",
      }}
    >
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <InfoSection {...HPinfo} />
      <InfoSectionUkraine {...HUinfo} />
      <ComingSoonSection />
      {/* <FirstSection />
      <OverviewSection />
      <AlvarsBubbleSection />
      <TimeLine />
      <WhyYBSection />*/}

      <Footer />
    </div>
  );
};

export default Home;
