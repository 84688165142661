import React from "react";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  Topline,
  Heading,
  Subtitle,
  BtnWrap,
  IMgWrap,
  Img,
  Button,
} from "./InfoElements";
const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topline,
  lightText,
  headline,
  darkText,
  description,
  buttonLabel,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <Topline lightText={lightText}>{topline}</Topline>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>{description}</Subtitle>
              </TextWrapper>
              <BtnWrap>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "https://donate.thedigital.gov.ua";
                  }}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                >
                  Ministry of Digitalization
                </Button>

                <Button
                  style={{ marginLeft: "30px", maxWidth: "700px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "https://k41community.fund/";
                  }}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                >
                  K41 Community Fund.
                </Button>
              </BtnWrap>
              <BtnWrap>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href =
                      "https://cardanoscan.io/pool/399c37889049086833ba82fbeeff226d6b387693ec32edc8ceca0b04";
                  }}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                >
                  Cardanoscan of Humanity-Pool. Find all stats.
                </Button>
              </BtnWrap>
            </Column1>
            <Column2>
              <IMgWrap>
                <Img src={img} alt={alt} />
              </IMgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
