export const HPinfo = {
  id: "about",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topline: "Humanity-Pool, Ticker: HUM",
  headline: "Our mission is to help the people and the planet. Stake with HUM.",
  description:
    "Our Pool is live now under the ticker: HUM. 3% margin and 5.5K ADA pledge. Because of the current war in Ukraine, all pool profits will be donated to humanitarian organizations helping people in the war regions of Ukraine. First donations go to the ministry of digital transformation of Ukraine and the K41 community fund.",
  buttonLabel: "Humanity-Fund",
  imgStart: false,
  img: require("../../images/Download.png")?.default,
  alt: "",
  dark: false,
  primary: true,
  darkText: true,
};

export const HUinfo = {
  id: "about",
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topline: "Helpukraine.art",
  headline: "We support HelpUkraine.art, an NFT project by Ukrainians",
  description:
    "This project directly helps by donating and all funds to the Ministry of Digital Transformation of Ukraine. Donate, help and get an NFT! Click below to get more information.",
  buttonLabel: "",
  imgStart: false,
  img: require("../../images/web/HelpUkraine-webpage.png")?.default,
  alt: "",
  dark: true,
  primary: false,
  darkText: false,
};
